import React, { useEffect } from "react"
import metaMaskLogo from "../../assets/images/logos/meta-mask.png"
import trustWalletLogo from "../../assets/images/logos/trust-wallet.png"
import walletConnectLogo from "../../assets/images/logos/wallet-connect.png"
import coinbaseLogo from "../../assets/images/logos/coinbase.png"
import { useStateValue } from "../../context/StateProvider"
import { connectWallet } from "../../utils/provider"

const wallets = [
    {
        id: "meta-mask",
        name: "Metamask",
        icon: metaMaskLogo
    },
    {
        id: "trust-wallet",
        name: "Trust Wallet",
        icon: trustWalletLogo
    },
    {
        id: "connect-wallet",
        name: "Wallet Connect",
        icon: walletConnectLogo
    },
    {
        id: "coinbase",
        name: "Coinbase",
        icon: coinbaseLogo
    }
]

const WalletConnectOptions = () => {
    const [{address, provider, wallet}, payload] = useStateValue()
    
    const connectWalletThroughProvider = async (walletId, accounts = []) => {
        payload({type: "WAIT", wait: true})
        try {
            const {provider, signer, address} = await connectWallet(walletId, accounts)
            payload({
                type: "PROVIDER",
                provider: {provider, signer, address, wallet: walletId}
            })
            payload({
                type: "MODAL"
            })
        } catch(err) {
            console.log(err)
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "error",
                    message: err?.reason?.message || err?.message || err
                }
            })
        } finally {payload({type: "WAIT"})}
    }

    useEffect(() => {
        if (address != null && wallet != null && provider === null) {
            connectWalletThroughProvider(wallet, [address])
        }
    }, [address, provider, wallet])
    return (
        <div className="content-container">
            <div className="instruct text-s">
                Select your preferred crypto wallet provider by clicking on the options below and following the simple connection steps provided.
            </div>
            <div className="options">
                {
                    wallets && wallets.length > 0 ?
                        wallets.map((wallet, i) =>
                            <div key={i} className="option" onClick={() => connectWalletThroughProvider(wallet.id)}>
                                <img src={wallet.icon} alt={wallet.name} />
                            </div>
                        )
                        : null
                }
            </div>
        </div>
    )
}

export default WalletConnectOptions