import React, { useEffect, useState } from "react"
import { IoCloseOutline } from "react-icons/io5"
import { useStateValue } from "../../context/StateProvider"

const Modal = () => {
    const [{modalState}, payload] = useStateValue()
    const [modalClass, setModalClass] = useState(null)
    const [showModal, setShowModal] = useState(false)

    const closeModal = () => {
        setModalClass("modal exit")
        setTimeout(() => {
            payload({type: "MODAL"})
            setShowModal(false)
        }, 400)
        
    }

    useEffect(() => {
        setModalClass("modal enter")
    }, [modalState])
    useEffect(() => {
        if (modalState.show === true)
            setShowModal(true)
        else closeModal()
    }, [modalState.show])
    return (
        showModal ?
            <div className="backdrop">
                <div className={modalClass}>
                    <div className="modal-head">
                        <IoCloseOutline onClick={closeModal} />
                    </div>
                    <div className="modal-content">
                        {modalState?.card}
                    </div>
                </div>
            </div> 
        : null
    )
}

export default Modal