import React from 'react'
import { copyContent } from '../../utils/general'
import { useStateValue } from '../../context/StateProvider'

const CopyClipboard = ({ addedClass, child, clipboardContent }) => {
    const [{}, payload] = useStateValue()

    const copyClipboard = () => {
        if (clipboardContent == null || clipboardContent === "")
            return
        copyContent(clipboardContent);
        payload({type: "COPY"})
    }
    return (
        <div className={addedClass || ""} onClick={copyClipboard}>{child}</div>
    )
}

export default CopyClipboard