import React from "react"
import { ImPowerCord } from "react-icons/im";
import { GrClose } from "react-icons/gr";
import { useStateValue } from "../../context/StateProvider";
import { connectWallet } from "../../utils/provider";

const DisconnectWallet = () => {
    const [{wallet, address, provider}, payload] = useStateValue()

    const disconnect = async () => {
        let _provider = provider
        if (provider == null) {
            const {provider: newProvider} = await connectWallet(wallet, [address])
            _provider = newProvider
        }
        await _provider.destroy()
        payload({ type: "PROVIDER" })
        payload({ type: "MODAL" })
        payload({ type: "NOTIFICATION", notification: {status: "warning", message: "Your wallet has been disconnect. Note that all web3 base functionality that requires signature will no longer work."}})
    }
    return (
        <div className="content-container">
            <div className="instruct text-m">
                Are you sure you want to disconnect? You may lose site functionality.
            </div>
            <div className="options disconnect">
                <div className="option" onClick={() => payload({type: "MODAL"})}>
                    <GrClose />
                    <div className="text-s text-weight-m">Cancel</div>
                </div>
                <div className="option danger" onClick={disconnect}>
                    <ImPowerCord />
                    <div className="text-s text-weight-m">Disconnect</div>
                </div>
            </div>
        </div>
    )
}

export default DisconnectWallet