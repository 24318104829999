import React, { useEffect, useState } from "react"
import ManageWallet from "../ManageWallet"

import logo from "../../assets/images/logo.svg"
import { useStateValue } from "../../context/StateProvider"
import ManageTheme from "./ManageTheme"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { RiMenuFoldFill, RiMenuFold2Fill } from "react-icons/ri"

const menus = [
    {
        id: 0,
        path: "/convert",
        title: "Convert",
        mobileIcon: <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 12.5 6.5 15 9 17.5 11.5 15 9 12.5ZM15 2.5C11.5724 2.5 8.76444 5.15304 8.51763 8.51763 5.15304 8.76445 2.5 11.5724 2.5 15 2.5 18.5899 5.41015 21.5 9 21.5 12.4276 21.5 15.2356 18.847 15.4824 15.4824 18.847 15.2356 21.5 12.4276 21.5 9 21.5 5.41015 18.5899 2.5 15 2.5ZM15.3234 13.4886C14.7575 11.1126 12.8874 9.24245 10.5114 8.67665 10.6772 6.34229 12.6234 4.5 15 4.5 17.4853 4.5 19.5 6.51472 19.5 9 19.5 11.3766 17.6577 13.3228 15.3234 13.4886ZM13.5 15C13.5 17.4853 11.4853 19.5 9 19.5 6.51472 19.5 4.5 17.4853 4.5 15 4.5 12.5147 6.51472 10.5 9 10.5 11.4853 10.5 13.5 12.5147 13.5 15ZM3 7C3 4.79086 4.79086 3 7 3H8.5V5H7C5.89543 5 5 5.89543 5 7V8.5H3V7ZM19 17V15.5H21V17C21 19.2091 19.2091 21 17 21H15.5V19H17C18.1046 19 19 18.1046 19 17Z"/>
        </svg>
    },
    {
        id: 1,
        path: "/guide/how-to-claim",
        title: "How to Claim",
        mobileIcon: <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.76282 17H20V5H4V18.3851L5.76282 17ZM6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM11 14H13V16H11V14ZM8.56731 8.81346C8.88637 7.20919 10.302 6 12 6C13.933 6 15.5 7.567 15.5 9.5C15.5 11.433 13.933 13 12 13H11V11H12C12.8284 11 13.5 10.3284 13.5 9.5C13.5 8.67157 12.8284 8 12 8C11.2723 8 10.6656 8.51823 10.5288 9.20577L8.56731 8.81346Z"/>
        </svg>
    },
    {
        id: 2,
        path: "/guide/how-to-convert",
        title: "How to Convert",
        mobileIcon: <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.76282 17H20V5H4V18.3851L5.76282 17ZM6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM11 14H13V16H11V14ZM8.56731 8.81346C8.88637 7.20919 10.302 6 12 6C13.933 6 15.5 7.567 15.5 9.5C15.5 11.433 13.933 13 12 13H11V11H12C12.8284 11 13.5 10.3284 13.5 9.5C13.5 8.67157 12.8284 8 12 8C11.2723 8 10.6656 8.51823 10.5288 9.20577L8.56731 8.81346Z"/>
        </svg>
    }
]

const Header = () => {
    const [{theme, tokenName, windowDimensions}] = useStateValue()
    const [headerClass, setHeaderClass] = useState("header")
    const [menuClass, setMenuClass] = useState("menu hidden")
    const [activeMenu, setActiveMenu] = useState(null)
    const [showMenu, setShowMenu] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()

    const navigateTo = (path) => {
        setShowMenu(false)
        navigate(path)
    }

    useEffect(() => {
        setActiveMenu(menus.filter(menu => menu.path === location.pathname)[0] || null)
    }, [location.pathname])
    useEffect(() => {
        if (windowDimensions.width <= 1024) setHeaderClass("header mobile")
        else setHeaderClass("header desktop")
        setShowMenu(false)
    }, [windowDimensions])
    useEffect(() => {
        if (showMenu) setMenuClass("menu visible")
        else {
            setMenuClass("menu transient")
            setTimeout(() => setMenuClass("menu hidden"), 250)
        }
    }, [showMenu])
    return (
        <header className={headerClass}>
            <nav>
                <div className="header-icon large-icon">
                    <div onClick={() => navigateTo("/")}><img src={logo} alt={tokenName} /></div>
                    <div className="menu-toggle">
                    {
                       headerClass.includes("mobile") && showMenu === false ? 
                        <RiMenuFoldFill onClick={() => setShowMenu(true)} /> : 
                        <RiMenuFold2Fill onClick={() => setShowMenu(false)} />
                    }
                    </div>
                </div>
                <div className={menuClass}>
                    <div className="menu-wrapper">
                        <div className="menu-container">
                            {
                                menus && menus.length > 0 ? menus.map(menu => 
                                <div 
                                    key={menu.id}
                                    onClick={() => navigateTo(menu.path)}
                                    className={`menu-item ${activeMenu?.id === menu.id ? "active" : null}`}>
                                    <div className="selector cursor-pointer">
                                        {
                                            headerClass.includes("mobile") ?
                                            menu.mobileIcon : null
                                        }
                                        <font>{menu.title}</font>
                                    </div>
                                </div>
                                ) : null
                            }
                        </div>
                        <div className="actions">
                            <ManageWallet doBefore={() => setShowMenu(false)} />
                            <span>|</span>
                            <ManageTheme />
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header