import React from "react"
import { useStateValue } from "../../context/StateProvider";
import { MdOutlinePlaylistAdd } from "react-icons/md";


const AddToken = ({ token, goHome }) => {
    const [_, payload] = useStateValue()
    const addToken = async () => {
        payload({type: "WAIT", wait: true})
        try {
            const wasAdded = await window.ethereum.request({
                method: "wallet_watchAsset",
                params: {
                    type: "ERC20", // Ethereum token standard
                    options: {
                        address: token?.tokenAddress,
                        symbol: token?.tokenSymbol,
                        decimals: token?.tokenDecimals,
                        image: token?.tokenImage, // Optional
                    },
                },
            });

            if (wasAdded) {
                payload({ type: "NOTIFICATION", notification: { status: "success", message: "Token added!" } });
            } else {
                payload({ type: "NOTIFICATION", notification: { status: "error", message: "Could not add token!" } });
            }
            setTimeout(goHome, 5000);
        } catch (error) {
            console.error(error)
        } finally {payload({type: "MODAL"});payload({type: "WAIT"})}
    }

    return (
        <div className="content-container">
            <div className="text-m padded-box-l">
                Add {token?.tokenName} {token?.tokenSymbol} to your wallet tokens list. This will enable you find your airdrop tokens within your wallet ready for use.
            </div>
            <div className="options">
                <button className="btn text-s" onClick={goHome}>Later</button>
                <button className="btn primary" onClick={addToken}><MdOutlinePlaylistAdd /> <span className="text-s">Add Token</span></button>
            </div>
        </div>
    )
}

export default AddToken;