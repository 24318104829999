import React from "react"
import { useStateValue } from "../../context/StateProvider";
import YouTubeEmbed from "./YouTubeEmbed";

const HowToConvert = () => {
    const [{tokenName, tokenSymbol, minReferrals}] = useStateValue()
    const steps = [
        { 
            step: 1,
            youtubeId: "FG8ylJPgJkc", 
            title: <font><span className="goldenrod">Step One:</span> Connect Wallet</font>, 
            description: <font>Before starting, make sure you have connected your wallet.</font> 
        },
        { 
            step: 2,
            youtubeId: "FG8ylJPgJkc", 
            title: <font><span className="goldenrod">Step Two:</span> Fulfil the Season's Task</font>, 
            description: <font>
                They are a few conditions that needs to be fulfilled before an <span className="goldenrod">Address</span> to convert their earned airdrop tokens.
                <ul>
                    <li>
                        <div className="text-s">The <span className="goldenrod">Address</span> must have referred up to {minReferrals} other wallet(s).</div>
                        <YouTubeEmbed videoId={"WFZP6WsNJEc"} styler="small" />
                    </li>
                </ul>
            </font> 
        },
        { 
            step: 3,
            youtubeId: "FG8ylJPgJkc", 
            title: <font><span className="goldenrod">Step Three:</span> Provide Swap Amount</font>, 
            description: <font>One activity that earns a claimer more <span className="text-weight-m">DP</span> is referring other claimers to successfully claim and join the <span className="text-weight-m">Airdrop Campaign</span>.</font> 
        },
        { 
            step: 4,
            youtubeId: "FG8ylJPgJkc", 
            title: <font><span className="goldenrod">Step Four:</span> Confirm Transaction</font>, 
            description: <font>Upon initializing <span className="text-weight-m">Claim Airdrop</span>, the smart contract powering the <span className="text-weight-m">Airdrop Campaign</span> would add a random amount equivalent to a value between <span className="text-weight-m">3% ~ 10%</span> of wallet's <span className="text-weight-m">{tokenName} balance at the time of claim</span>.</font>
        }
    ];

    return (
        <div className="how-to-container">
            <h1 className="how-to-title">How to Convert {tokenName} Airdrop Tokens</h1>
            <YouTubeEmbed videoId={"FG8ylJPgJkc"} styler="large" />
            <p className="how-to-intro">
                Follow the steps below to successfully complete this task. Make sure to read each instruction carefully.
            </p>

            <div className="steps-container">
                {steps.map(({ step, title, description, youtubeId }) => (
                    <div key={step} className="step-card">
                        <h2 className="step-title">{title}</h2>
                        <YouTubeEmbed videoId={youtubeId} styler="medium" />
                        <p className="step-description">{description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HowToConvert