import React from "react"
import { useStateValue } from "../../context/StateProvider"
import tokenIcon from "../../assets/images/token.png"
import { FaLongArrowAltDown } from "react-icons/fa";
import { formatNumber } from "../../utils/general";
import { AiOutlineSwap } from "react-icons/ai";

const TradeDisclaimer = ({ amount, rate, priceImpact, handleSubmit }) => {
    const [{ tokenSymbol }, payload] = useStateValue()

    return (
        <div className="content-container">
            <div className="instruct text-m text-weight-m">
                Confirm Swap
            </div>
            <div className="disclaimer">
                <div className="container">
                    <div className="row">
                        <div className="tokens">
                            <div className="token-row">
                                <div>
                                    <img src={tokenIcon} /> <font>{formatNumber(amount)}</font>
                                </div>
                                <div>
                                    {`a${tokenSymbol.toUpperCase()}`}
                                </div>
                            </div>
                            <div className="demarcator"><FaLongArrowAltDown /></div>
                            <div className="token-row">
                                <div>
                                    <img src={tokenIcon} /> <font>{formatNumber(amount)}</font>
                                </div>
                                <div>
                                    {tokenSymbol.toUpperCase()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="text-s text-style-italic">
                            You will receive at least <span className="text-weight-m">{formatNumber(amount)} {tokenSymbol.toUpperCase()}</span> or the transaction will revert.
                        </div>
                    </div>
                    <div className="row">
                        <div className="trade-info text-s">
                            <div className="row">
                                <div className="title">Price</div>
                                <div className="value">
                                    <span>{rate}</span>
                                    <span>{`a${tokenSymbol.toUpperCase()}`} / {tokenSymbol.toUpperCase()}</span>
                                    <AiOutlineSwap />
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">Minimum received</div>
                                <div className="value">{formatNumber(amount)} {tokenSymbol.toUpperCase()}</div>
                            </div>
                            <div className="row">
                                <div className="title">Price impact</div>
                                <div className="value">{priceImpact}%</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <button className="btn primary full-width" onClick={handleSubmit}>Confirm Swap</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TradeDisclaimer