import React from 'react'

const Footer = () => {
    return (
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <iframe
                src="https://cdn.coinzilla.io/html_customers/d5d445bc92a8522d83688510d631e163/index.html" // Replace with your URL
                title="Example Site"
                height="100%"
                style={{ border: 'none', margin: 0, padding: 0, width: "100%", maxWidth: "750px" }}
                allowFullScreen
            />
        </div>
    )
}

export default Footer