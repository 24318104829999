import React, { Fragment, useEffect, useState } from "react"
import usdtLogo from "../assets/images/token.png"
import { formatNumber, formatToCurrency } from "../utils/general"
import { useStateValue } from "../context/StateProvider"
import { AiOutlineSwap } from "react-icons/ai"
import ManageWallet from "../components/ManageWallet"
import TradeDisclaimer from "../components/templates/TradeDisclaimer"
import { Contract, formatUnits, parseUnits } from "ethers"
import { connectWallet } from "../utils/provider"
import loadingGif from "../assets/images/loading.gif"

const Swap = () => {
    const [{
        signer, 
        wallet,
        network, 
        address,
        provider, 
        tokenABI,
        contractABI, 
        tokenSymbol, 
        minReferrals, 
        tokenAddress,
        contractAddress,
        addressReferrals
    }, payload] = useStateValue()
    const [tokenUSDValue, setTokenValue] = useState(0.998152)
    const [airdropBalance, setAirdropBalance] = useState(0)
    const [tokenBalance, setTokenBalance] = useState(0)
    const [tokenDecimals, setTokenDecimals] = useState(18)
    const [airdropTokenPortion, setAirdropTokenPortion] = useState(0)
    const [gasFee, setGasFee] = useState(0.002)
    const [baseValue, setBaseValue] = useState("")
    const [btnText, setBtnText] = useState("Input Amount")
    const [btnClass, setBtnClass] = useState("primary")
    const [hasError, setHasError] = useState(false)
    const [loadingWallet, setLoadingWallet] = useState(false)
    const [contractInstance, setContractInstance] = useState(null)

    const handleInput = (e) => {
        if (loadingWallet)
            return
        let value = e.target.value;
        if (!["", null].includes(value)) {
            value = value.toString().replace(" ", "").split("").reduce((p, c) => {
                if (!isNaN(c) || (c == "." && !p.split("").includes("."))) p += c;
                return p;
            }, "");
            
            if ([NaN, Infinity, "NaN", "Infinity", ""].includes(value)) {
                setBaseValue("")
            } else {
                const sanitizedValue = parseFloat(value[value.length - 1] == "." ? value.slice(0, value.length - 1) : value);
                setBaseValue(sanitizedValue)
            }
        }else 
            setBaseValue("")
    }
    const getWalletData = async () => {
        if (!address || address.length !== 42)
            return
        let signerCopy = signer;
        let providerCopy = provider;
        setLoadingWallet(true);

        try {
            if ([provider, signer].includes(null)) {
                const {provider: newProvider, signer: newSigner} = await connectWallet(wallet, [address])
                payload({
                    type: "PROVIDER",
                    provider: {provider: newProvider, signer: newSigner}
                })
                signerCopy = newSigner
                providerCopy = newProvider
            }  
            
            const tokenContract = new Contract(tokenAddress, tokenABI, signerCopy);
            const airdropContract = new Contract(contractAddress, contractABI, signerCopy);
            setContractInstance(airdropContract)
            const airdropTokenAddress = await airdropContract.airdropTokenAddress();
            const airdropTokenContract = new Contract(airdropTokenAddress, tokenABI, providerCopy);
            const decimals = await airdropTokenContract.decimals();
            setTokenDecimals(decimals);
            const tokenBalance = await tokenContract.balanceOf(address);

            const airdropTokenBalance = await airdropTokenContract.balanceOf(address);
            const [_length, _referrals] = await airdropContract.referralsOf(address);

            payload({type: "REFERRALS", referrals: _referrals})
            setTokenBalance(parseFloat(formatUnits(tokenBalance, decimals)));
            setAirdropBalance(parseFloat(formatUnits(airdropTokenBalance, decimals)));
        } catch(error) {
            console.error(error)
        } finally {
            setLoadingWallet(false);
        }
    }
    const handleSubmit = async () => {
        if (hasError || loadingWallet) return
        payload({
            type: "MODAL",
            show: true,
            card: <TradeDisclaimer amount={baseValue} rate={1} priceImpact={0} handleSubmit={submitTransaction} />
        });
    }
    const setPortion = (portion) => {
        const selectedPortion = airdropBalance * (portion/100);
        setBaseValue(selectedPortion);
        setAirdropTokenPortion(portion);
    }
    const submitTransaction = async () => {
        payload({ type: "MODAL" })
        payload({ type: "WAIT", wait: true })
        
        try {
            if (contractInstance === null)
                throw null
            const tx = await contractInstance.convert(parseUnits(baseValue.toString(), tokenDecimals))
            tx.wait()
            payload({
                type: "NOTIFICATION", 
                notification: {
                    status: "success", 
                    message: `Swapped ${baseValue.toFixed(4)} a${tokenSymbol} for ${baseValue.toFixed(4)} ${tokenSymbol} successfully`
                }
            })
            getWalletData()
        } catch(error) {
            payload({
                type: "NOTIFICATION", 
                notification: {
                    status: "error", 
                    message: "Could not complete transaction. Please try again."
                }
            })
        } finally {
            payload({ type: "WAIT"})
        }
    }

    useEffect(() => {
        if (address && address.length === 42) {
            getWalletData()
        }
    }, [])
    useEffect(() => {
        let _text, _hasError, _class = "disabled"
        if (airdropBalance <= 0 || addressReferrals.length < minReferrals) {
            _hasError = true
            _text = "Not Allowed Yet"
            _class = "danger"
        } else if (parseFloat(baseValue) > parseFloat(airdropBalance)) {
            _hasError = true
            _text = "Insufficient Airdrop Balance"
            _class = "danger"
        } else if (baseValue === 0 || baseValue === "") {
            _text = "Input Amount"
        } else {
            _hasError = false
            _text = "Swap Token"
            _class = "primary"
        }
        setBtnText(_text)
        setBtnClass(_class)
        setHasError(_hasError)
    }, [baseValue, airdropBalance])
    return (
        <Fragment>
            <main>
                <div className="swap-box">
                    <div className="swap-text">
                        Swap {`a${tokenSymbol}`} for {tokenSymbol} at 1:1 rate.
                    </div>
                    <div className="swap">
                        <div id="base" className="token-data">
                            <div className="container">
                                <div className="row">
                                    <div className="text-m text-weight-m">From</div>
                                    <div className="portions">
                                        <div
                                            onClick={() => setPortion(25)} 
                                            className={`part text-weight-l cursor-pointer ${airdropTokenPortion === 25 ? "active" : null}`}>25%</div>
                                        <div
                                            onClick={() => setPortion(50)} 
                                            className={`part text-weight-l cursor-pointer ${airdropTokenPortion === 50 ? "active" : null}`}>50%</div>
                                        <div
                                            onClick={() => setPortion(75)} 
                                            className={`part text-weight-l cursor-pointer ${airdropTokenPortion === 75 ? "active" : null}`}>75%</div>
                                        <div
                                            onClick={() => setPortion(100)} 
                                            className={`part text-weight-l cursor-pointer ${airdropTokenPortion === 100 ? "active" : null}`}>Max</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="token-container">
                                        <div className="icon">
                                            <img src={usdtLogo} />
                                        </div>
                                        <div className="name">{`a${tokenSymbol.toUpperCase()}`}</div>
                                    </div>
                                    <div className="value-container">
                                        <input type="text" placeholder="0.0000" value={baseValue} onChange={handleInput} />
                                    </div>
                                </div>
                                <div className="row collapse">
                                    <div></div>
                                    <div className="text-s gray-color">
                                        {parseFloat(baseValue) > 0 ? formatToCurrency((tokenUSDValue * parseFloat(baseValue)).toFixed(2)) : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="token-balance">
                                        Balance: {
                                            loadingWallet ? <img src={loadingGif} alt="loading..." height={15} /> : 
                                            <span>{formatNumber(airdropBalance)}</span>
                                        }
                                    </div>
                                    <div className="token-value">
                                        {
                                            loadingWallet ? <img src={loadingGif} alt="loading..." height={15} /> : 
                                            <span>{formatToCurrency(tokenUSDValue * airdropBalance)}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="pair" className="token-data">
                            <div className="container">
                                <div className="row">
                                    <div className="text-m text-weight-m">To (Estimated)</div>
                                </div>
                                <div className="row">
                                    <div className="token-container">
                                        <div className="icon">
                                            <img src={usdtLogo} />
                                        </div>
                                        <div className="name">{tokenSymbol.toUpperCase()}</div>
                                    </div>
                                    <div className="value-container">
                                        <input type="text" placeholder="0.0000" value={baseValue} readOnly />
                                    </div>
                                </div>
                                <div className="row collapse">
                                    <div></div>
                                    <div className="text-s gray-color">{parseFloat(baseValue) > 0 ? formatToCurrency((tokenUSDValue * parseFloat(baseValue)).toFixed(2)) : null}</div>
                                </div>
                                <div className="row">
                                    <div className="token-balance">
                                        Balance: {
                                            loadingWallet ? <img src={loadingGif} alt="loading..." height={15} /> : 
                                            <span>{formatNumber(tokenBalance)}</span>
                                        }
                                    </div>
                                    <div className="token-value">
                                    {
                                        loadingWallet ? <img src={loadingGif} alt="loading..." height={15} /> : 
                                        <span>{formatToCurrency(tokenUSDValue * tokenBalance)}</span>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rates">
                            <span>1 {`a${tokenSymbol.toUpperCase()}`}</span> <span>=</span> <span>1 {tokenSymbol.toUpperCase()}</span> <AiOutlineSwap />
                        </div>
                        <div className="checks">
                            <div className="check">
                                <input type="checkbox" checked={airdropBalance > 0} id="airdrop-check" readOnly />
                                <label htmlFor="airdrop-check">Wallet must have sufficient {`a${tokenSymbol}`} tokens</label>
                            </div>
                            <div className="check">
                                <input type="checkbox" checked={addressReferrals.length >= minReferrals} id="reserve-check" readOnly />
                                <label htmlFor="reserve-check">Wallet must have referred {minReferrals} address(es) or airdrop vault has been emptied out.</label>
                            </div>
                        </div>
                        <div className="form-action">
                        {
                            address == null ? <ManageWallet /> : 
                                <button 
                                    className={`btn wallet-btn ${btnClass}`} type="submit"
                                    onClick={handleSubmit}>{btnText}</button>
                        }
                        </div>
                        {
                            parseFloat(baseValue) > 0 && !hasError ? 
                            <div className="swap-stats">
                                <div className="container">
                                    <div className="row">
                                        <div className="title">Minimum Receive</div>
                                        <div className="values">
                                            <div className="token">{formatNumber(baseValue)} {tokenSymbol.toUpperCase()}</div>
                                            <div>~</div>
                                            <div className="value">{formatToCurrency(tokenUSDValue * baseValue)}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="title">Est.Gas Fee</div>
                                        <div className="values">
                                            <div className="token">{formatNumber(gasFee)} {network?.symbol?.toUpperCase()}</div>
                                            <div>~</div>
                                            <div className="value">{formatToCurrency((network?.value || 0) * gasFee)}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="title">Price Impact</div>
                                        <div className="values">
                                            <div className="value">0%</div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null 
                        }
                    </div>
                </div>
            </main>
        </Fragment>
    )
}

export default Swap