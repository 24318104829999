import React from "react";
import { LiaDropbox } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../context/StateProvider";

const Eligible = () => {
    const [{}, payload] = useStateValue()
    const navigate = useNavigate()

    const gotoClaim = () => {
        payload({type: "MODAL"})
        navigate("/claim")
    }
    return (
        <div className="content-container">
            <div className="instruct text-m">
                <h1 className="success-color">Congratulations!</h1>
                This address is deemed eligible to claim this season's airdrop. Click the button below to proceed.
            </div>
            <div className="options">
                <button className="btn primary text-m" onClick={gotoClaim}>
                    <LiaDropbox />
                    <span>Claim Airdrop</span>
                </button>
            </div>
        </div>
    )
}

export default Eligible