
import { getWindowDimensions, getStorage, setStorage, removeStored } from "../utils/general"
import tokenABI from "../utils/token.abi.json";
import contractABI from "../utils/contract.abi.json";

const {
    REACT_APP_API_URL,
    REACT_APP_SITE_TITLE,
    REACT_APP_TOKEN_NAME,
    REACT_APP_TOKEN_SYMBOL,
    REACT_APP_TOKEN_ADDRESS,
    REACT_APP_COUNTDOWN_END,
    REACT_APP_INTRO_MESSAGE,
    REACT_APP_SEO_DESCRIPTION,
    REACT_APP_CONTRACT_ADDRESS
} = process.env

export const initialState = {
    API_URL: REACT_APP_API_URL,
    windowDimensions: getWindowDimensions(),
    theme: getStorage("cookies", "theme") || "light",
    siteTitle: REACT_APP_SITE_TITLE,
    seoDescription: REACT_APP_SEO_DESCRIPTION,
    tokenName: REACT_APP_TOKEN_NAME,
    tokenSymbol: REACT_APP_TOKEN_SYMBOL,
    tokenAddress: REACT_APP_TOKEN_ADDRESS,
    contractAddress: REACT_APP_CONTRACT_ADDRESS,
    introMessage: REACT_APP_INTRO_MESSAGE,
    tokenABI,
    contractABI,
    minReferrals: 5,
    addressReferrals: getStorage("cookies", "referrals") ? JSON.parse(getStorage("cookies", "referrals")) : [],
    network: {name: "Binance Smart Chain", symbol: "BNB", value: 604.67},
    provider: null,
    signer: null,
    wallet: getStorage("cookies", "wallet"),
    referrer: getStorage("cookies", "referrer"),
    address: getStorage("cookies", "address"),
    countDownEnds: new Date(REACT_APP_COUNTDOWN_END).getTime(),
    modalState: { show: false, card: null },
    shouldWait: false,
    newToken: null,
    showCopiedNotification: false,
    contractData: getStorage("cookies", "contract") ? JSON.parse(getStorage("cookies", "contract")) : null,
    pointsEarned: getStorage("cookies", "earnings") ? JSON.parse(getStorage("cookies", "earnings")) : {}
}

const reducer = (state, action) => {
    switch (action.type) {
        case "THEME":
            const newTheme = state.theme === "dark" ? "light" : "dark"
            setStorage("cookies", "theme", newTheme)
            document.body.classList = newTheme
            return {
                ...state,
                theme: newTheme
            }
        case "DIMENSIONS":
            return {
                ...state,
                windowDimensions: getWindowDimensions()
            }
        case "MODAL":
            return {
                ...state,
                modalState: { show: action?.show || false, card: action?.card || null }
            }
        case "PROVIDER":
            if (action?.provider) {
                if (action?.provider?.wallet)
                    setStorage("cookies", "wallet", action?.provider?.wallet)
                if (action?.provider?.address)
                    setStorage("cookies", "address", action?.provider?.address)
            } else {
                removeStored("cookies", "wallet")
                removeStored("cookies", "address")
                removeStored("cookies", "referrer")
                removeStored("cookies", "earnings")
            }
            return {
                ...state,
                address: action?.provider ? action?.provider?.address || state.address : null,
                provider: action?.provider ? action?.provider?.provider || state.provider : null,
                signer: action?.provider ? action?.provider?.signer || state.signer : null,
                wallet: action?.provider ? action?.provider?.wallet || state.wallet : null
            }
        case "CONTRACT":
            if (action?.contractData) {
                setStorage("cookies", "contract", JSON.stringify(action.contractData), 1/(24*12))
            }
            return {
                ...state,
                contractData: action?.contractData || null
            }
        case "NOTIFICATION":
            return {
                ...state,
                notification: action?.notification
            }
        case "REFERRER":
            if (action?.referrer) {
                setStorage("cookies", "referrer", action?.referrer)
            }
            return {
                ...state,
                referrer: action?.referrer || null
            }
        case "REFERRALS":
            if (action?.referrals) {             
                setStorage("cookies", "referrals", JSON.stringify(action?.referrals), 1/(24*12))
            }
            return {
                ...state,
                addressReferrals: action?.referrals || []
            }
        case "WAIT":
            return {
                ...state,
                shouldWait: action?.wait || false
            }
        case "COPY":
            return {
                ...state,
                showCopiedNotification: !state.showCopiedNotification
            }
        case "ADD_TOKEN":
            return {
                ...state,
                newToken: action?.token ? {token: action.token, goHome: action?.goHome} : null
            }
        case "EARN":
            const earningsCopy = state.pointsEarned
            if (action?.taskId && action?.point) {
                earningsCopy[action?.taskId] = action?.point
                setStorage("cookies", "earnings", JSON.stringify(earningsCopy))
            }
            return {
                ...state,
                pointsEarned: earningsCopy
            }
        default:
            return state;
    }
}

export default reducer;