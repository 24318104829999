import React from "react"

import loadingIcon from "../../assets/images/loading.gif"
import { useStateValue } from "../../context/StateProvider"

const WaitScreen = () => {
    const [{shouldWait}] = useStateValue()
    return (
        shouldWait ?
        <div className="backdrop">
            <div className="wait-container">
                <div className="wait-icon">
                    <img src={loadingIcon} alt="Processing..." />
                </div>
            </div>
        </div> : null
    )
}

export default WaitScreen