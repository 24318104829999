import React, { Fragment, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/Home";
import NotFound from "./components/NotFound";
import Swap from "./routes/Swap";
import Guide from "./routes/Guide";
import Claim from "./routes/Claim";
import Notifications from "./components/Notifications";

import "./assets/styles/main.css";
import "./assets/styles/colors.css";
import "./assets/styles/boxes.css";
import "./assets/styles/fonts.css";
import "./assets/styles/media.css";
import "./assets/styles/skins.css";
import "./assets/styles/animations.css";
import { useStateValue } from "./context/StateProvider";
import Main from "./components/templates/Main";
import CopyNotification from "./components/templates/CopyNotification";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main child={<Home />} />,
    },
    {
        path: "/claim",
        element: <Main child={<Claim />} />,
    },
    {
        path: "/convert",
        element: <Main child={<Swap />} />,
    },
    {
        path: "/guide/:type",
        element: <Main child={<Guide />} />,
    },
    {
        path: "*",
        element: <Main child={<NotFound />} />,
    }
]);


const App = () => {
    const [{theme, siteTitle, seoDescription}, payload] = useStateValue()
    const handleResize = () => {
        payload ({
            type: "DIMENSIONS"
        })
    }
    useEffect(() => {
        document.body.classList = theme
        window.addEventListener("resize", handleResize)

        document.getElementById("title").innerHTML = siteTitle
        const themeColor = theme === "light" ? "#c5c9d1" : "#121213"
        document.getElementById("theme-color").setAttribute("content", themeColor)
        document.getElementById("seo-description").setAttribute("content", seoDescription)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])
    return (
        <Fragment>
            <CopyNotification />
            <Notifications />
            <RouterProvider router={router} />
        </Fragment>
    )
}

export default App