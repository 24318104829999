import React, { Fragment, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import { capitalizeWords } from "../utils/general";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import HowToClaim from "../components/templates/HowToClaim";
import HowToConvert from "../components/templates/HowToConvert";

const Guide = () => {
    const [selectedGuide, setSelectedGuide] = useState()
    const [guideContent, setGuideContent] = useState(null);
    const tradeType = useParams().type;

    useEffect(() => {
        const type = tradeType?.replaceAll("-", " ")
        setSelectedGuide(type)
        if (type.toLowerCase().includes("claim"))
            setGuideContent(<HowToClaim />)
        if (type.toLowerCase().includes("convert"))
            setGuideContent(<HowToConvert />)
    }, [tradeType])
    return (
        <Fragment>
            <main>
                <section>
                    <div className="map">
                        <div className="pos">Guide</div>
                        <div className="demarcator">
                            <MdOutlineKeyboardDoubleArrowRight />
                        </div>
                        <div className="pos">{capitalizeWords(selectedGuide)}</div>
                    </div>
                </section>
                <section className="container guide-menu">
                    <div className="row top">
                        <Link to="/guide/how-to-claim" className={`column${selectedGuide?.toLowerCase()?.includes("claim") ? " active" : ""}`}>How To Claim</Link>
                        <Link to="/guide/how-to-convert" className={`column${selectedGuide?.toLowerCase()?.includes("convert") ? " active" : ""}`}>How To Convert</Link>
                    </div>
                    <div className="row bottom"></div>
                </section>
                <section className="guide-content">{guideContent}</section>
            </main>
        </Fragment>
    )
}

export default Guide