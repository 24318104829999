import React, { useEffect, useState } from "react"
import { useStateValue } from "../../context/StateProvider"

const CopyNotification = () => {
    const [{showCopiedNotification}, payload] = useStateValue()
    const [classList, setClassList] = useState("copy-notification")
    const [showNotification, setShowNotification] = useState(false)

    useEffect(() => {
        let timeout
        if (showCopiedNotification) {
            setClassList("copy-notification enter")
            setShowNotification(true)
                
            timeout = setTimeout(() => {payload({type: "COPY"})}, 3000)
        } else {
            setClassList("copy-notification exit")
            timeout = setTimeout(() => {
                setShowNotification(false)
            }, 350)
        }

        return (() => {
            if (timeout !== null)
                clearTimeout(timeout)
        })
    }, [showCopiedNotification])
    return (
        showNotification ? <div className={classList}><div className="text-weight-s text-m success-color">copied</div></div> : null
    )
}

export default CopyNotification