import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import { externalNavigation } from '../utils/general'
import { FcHome } from "react-icons/fc";
import { RiSwapBoxFill } from 'react-icons/ri';

const ErrorPage = () => {
  return (
    <div className='backdrop'>
        <div className="error-content">
            <div className="error-desc">
                <div className="bold-text large-text secondary-color-text">!Oops, something went wrong.</div>
                <div className="small-text large-x-mg-box large-z-pd-box">
                    It seems we dropped a tool accidentally. You can navigate to other properties of this website or contact support if this continues.
                </div>
                <div className="full-width flex-box flex-align-center-box flex-justify-apart">
                    <div className="small-text large-x-mg-box">
                        <Button 
                            className="bold-text primary-color-background"
                            clickMethod={() => externalNavigation("/")}
                            innerHTML="Return home"
                            leftIcon={<FcHome />}
                        />
                    </div>
                    <div className="small-text large-x-mg-box">
                        <Button 
                            className="bold-text"
                            clickMethod={() => externalNavigation("/trade")}
                            innerHTML="Go to trades"
                            leftIcon={<RiSwapBoxFill />}
                        />
                    </div>
                </div>
            </div>
            <div className="image-illustrator"></div>
        </div>
    </div>
  )
}

export default ErrorPage