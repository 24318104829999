import React from "react";

const YouTubeEmbed = ({ videoId, styler = "" }) => {
    return (
        <div>
            <iframe
                className={`youtube-embed ${styler}`}
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default YouTubeEmbed;
