import React, { useEffect, useState } from "react";
import { MdWarningAmber, MdErrorOutline, MdCheck, MdOutlineClose } from "react-icons/md";
import { useStateValue } from "../context/StateProvider";

const Notifications = () => {
    const [{ notification }, payload] = useStateValue();
    const [notificationClass, setNotificationClass] = useState("notification hidden");
    const [isPaused, setIsPaused] = useState(false);
    const [count, setCount] = useState(null);

    const closeNotification = () => {
        setNotificationClass("notification disabled");
        setTimeout(() => {
            payload({
                type: "NOTIFICATION",
                notification: null
            })
            setNotificationClass("notification hidden");
        }, 350)
    }
    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };

    useEffect(() => {
        let interval;
        if (notification != null) {
            let _count = notification.duration || count || 5;
            setNotificationClass("notification enabled");
            interval = setInterval(() => {
                if (!isPaused) {
                    _count--;
                    setCount(_count);
                }
                if (_count == 0) {
                    setCount(null);
                    clearInterval(interval);
                    closeNotification();
                }
            }, 1000);
        }

        return (() => {
            if (interval != null) {
                clearInterval(interval);
            }
        })
    }, [notification, isPaused])
    return (
        notification ? (
            <div className="notification-container">
                <div 
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className={`${notificationClass} ${notification.status}`}>
                    <MdOutlineClose className="absolute-right cursor-pointer" onClick={closeNotification} />
                    <div className="status-icon">
                    {notification.status === "success" ? <MdCheck /> : notification.status === "error" ? <MdErrorOutline /> : <MdWarningAmber />}
                    </div>
                    <div className="notification-message">{notification.message}</div>
                </div>
            </div>
        ) : null
    )
}

export default Notifications