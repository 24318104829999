import { ethers } from "ethers"
import WalletLink from "@coinbase/wallet-sdk"
import WalletConnectProvider from "@walletconnect/ethereum-provider"
import { getStorage } from "./general"

const {
    REACT_APP_CHAIN_ID,
    REACT_APP_SITE_TITLE,
    REACT_APP_PROVIDER_RPC
} = process.env

export async function connectWallet(walletId, accounts = []) {
    function wallet(provider) {
        return new Promise(async function(resolve, reject) {
            try {
                const _provider = new ethers.BrowserProvider(provider)
                const signer = await _provider.getSigner()
                const address = await signer.getAddress()
                return resolve([_provider, signer, address])
            } catch(err) {
                return reject(err)
            }
        })
    }
    if (["trust-wallet", "meta-mask"].includes(walletId)) return new Promise(async function(resolve, reject) {
        if (typeof window.ethereum !== "undefined") {
            try {
                let _provider
                if (accounts.length > 0) {
                    try {
                        accounts = walletId === "trust-wallet" && window.trustwallet ? 
                            await window.trustwallet.request({ method: "eth_accounts" }) :
                            await window.ethereum.request({ method: "eth_accounts" })
                        
                    } catch(err) {}
                }
                if (accounts.length <= 0) {
                    function selectProvider(id, provider) {
                        if (id === "trust-wallet" && provider.isTrust || id === "meta-mask" && provider.isMetaMask)
                            return provider
                        return null
                    }
                    if (window.ethereum.providers) {
                        const providers = window.ethereum.providers
                        for (const ethProvider of providers) {
                            _provider = selectProvider(walletId, ethProvider)
                        }
                    }
                    else if (walletId === "trust-wallet") {
                        if (!window.trustwallet)
                            return reject({
                                message: "Trust Wallet has not been installed on this browser. Please install and try again."
                            })
                        _provider = selectProvider(walletId, window.trustwallet)
                    }else {
                        if (walletId === "meta-mask" && !window.ethereum.isMetaMask)
                            return reject({
                                message: "MetaMask has not been installed on this browser. Please install and try again."
                            })
                        _provider = selectProvider(walletId, window.ethereum)
                    }
                    if (_provider != null)
                        await _provider.request({ method: "eth_requestAccounts" })
                    else return reject({
                        message: "The selected wallet provider has not been installed on this browser, please install the required wallet provider and try again."
                    })
                } else {
                    _provider = walletId === "trust-wallet" && window.trustwallet ? window.trustwallet : window.ethereum
                }
                const [provider, signer, address] = await wallet(_provider)
                return resolve({ provider, signer, address })
    
            } catch (err) {
                return reject(err)
            }
        } else {
            return reject({message: "No Ethereum provider detected. Make sure you have Trust Wallet or another wallet installed."})
        }
    })
    else if (walletId === "coinbase") return new Promise(async function(resolve, reject) {
        try {
            const walletLink = new WalletLink({
                appName: REACT_APP_SITE_TITLE,
                darkMode: getStorage("cookies", "theme") == "dark"
            })
            const coinbaseProvider = walletLink.makeWeb3Provider(REACT_APP_PROVIDER_RPC, REACT_APP_CHAIN_ID)
            await coinbaseProvider.request({ method: 'eth_requestAccounts' })
            const [provider, signer, address] = await wallet(coinbaseProvider)
            return resolve({ provider, signer, address })
        } catch(err) {
            return reject(err)
        }
    })
    else return new Promise(async function(resolve, reject) {
        try {
            const rpc = {[REACT_APP_CHAIN_ID]: REACT_APP_PROVIDER_RPC,}
            const walletConnectProvider = new WalletConnectProvider({ rpc, chainId: REACT_APP_CHAIN_ID })      
            await walletConnectProvider.enable();
            const [provider, signer, address] = await wallet(walletConnectProvider)
            return resolve({ provider, signer, address })
        } catch(err) {
            return reject(err)
        }
    })
}
