import React from "react"
import { useStateValue } from "../../context/StateProvider";

const HowToClaim = () => {
    const [{tokenName, tokenSymbol}] = useStateValue()
    const steps = [
        { 
            step: 1, 
            title: <font><span className="goldenrod">Step One:</span> Connect Wallet</font>, 
            description: <font>Before starting, make sure you have connected your wallet. This is to ensure that all rewards earned on the platform is sent to the right wallet.</font> 
        },
        { 
            step: 2, 
            title: <font><span className="goldenrod">Step Two:</span> Perform Social Tasks</font>, 
            description: <font>On the home, you will find some social tasks provided for a unique address, fulfil these tasks and earn extra <span className="font-weight-m">Drop Power (DP)</span>. This DP increases your possible airdrop size (ie. will increase your airdrop allocation.)</font> 
        },
        { 
            step: 3, 
            title: <font><span className="goldenrod">Step Three:</span> Refer Others and Earn Faster</font>, 
            description: <font>One activity that earns a claimer more <span className="text-weight-m">DP</span> is referring other claimers to successfully claim and join the <span className="text-weight-m">Airdrop Campaign</span>.</font> 
        },
        { 
            step: 4, 
            title: <font><span className="goldenrod">Step Four:</span> HODL ${tokenName} ({tokenSymbol})</font>, 
            description: <font>Upon initializing <span className="text-weight-m">Claim Airdrop</span>, the smart contract powering the <span className="text-weight-m">Airdrop Campaign</span> would add a random amount equivalent to a value between <span className="text-weight-m">3% ~ 10%</span> of wallet's <span className="text-weight-m">{tokenName} balance at the time of claim</span>.</font>
        },
        { 
            step: 5, 
            title: <font><span className="goldenrod">Step Five:</span> Claim Airdrop</font>, 
            description: <font>A claimer can claim airdrop before referring others and still get their extra airdrop when their referred claimer claims their token. To finalize the entire process and receive your airdrop, each claimer should ensure any or all of the required steps are fulfilled, after which the <span className="text-weight-m">Claim Airdrop</span> button would be made visible and clickable.</font>
        }
    ];
    return (
        <div className="how-to-container">
            <h1 className="how-to-title">How to Claim {tokenName} Airdrop Tokens</h1>
            <p className="how-to-intro">
                Follow the steps below to successfully complete this task. Make sure to read each instruction carefully.
            </p>

            <div className="steps-container">
                {steps.map(({ step, title, description }) => (
                    <div key={step} className="step-card">
                        <h2 className="step-title">{title}</h2>
                        <p className="step-description">{description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HowToClaim