import React, { Fragment, useEffect } from "react"
import Header from "./Header"
import Modal from "./Modal"
import WaitScreen from "./WaitScreen"
import AddToken from "./AddToken"
import { useStateValue } from "../../context/StateProvider"
import Footer from "./Footer"

const Main = ({ child }) => {
    const [{referrer}, payload] = useStateValue()
    
    useEffect(() => {
        if (referrer == null) {
            const searchQueryString = window.location.search
            const searchObject = Object.fromEntries(searchQueryString.slice(1, searchQueryString.length).split("&").map(query => query.split("=")))
            
            if ("ref" in searchObject)
                payload({type: "REFERRER", referrer: searchObject.ref})
        }
    }, [])
    return (
        <Fragment>
            <Header />
            {child}
            <Footer />
            <Modal />
            <WaitScreen />
        </Fragment>
    )
}

export default Main