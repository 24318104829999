import React, { useEffect } from "react"
import { useStateValue } from "../context/StateProvider"
import WalletConnectOptions from "./templates/WalletConnectOptions"
import DisconnectWallet from "./templates/DisconnectWallet"
import { PiWallet, PiAddressBook } from "react-icons/pi";

const ManageWallet = ({doBefore}) => {
    const [{ address }, payload] = useStateValue()
    
    const handleWallet = async () => {
        if (doBefore != null)
            await doBefore()
        payload({type: "MODAL", show: true, card: address != null ? <DisconnectWallet /> : <WalletConnectOptions />})
    }

    return (
        <div 
            className="btn primary wallet-btn"
            onClick={handleWallet}>
        {
            address != null && address.length === 42 ? 
                <div className="connected">
                    <PiAddressBook />
                    <div className="address">
                        <span>{address.slice(0, 5)}</span>
                        <span>****</span>
                        <span>{address.slice(37, 42)}</span>
                    </div>
                </div>
            :
                <div className="disconnected">
                    <PiWallet />
                    <span>Connect Wallet</span>
                </div>
        }
        </div>
    )
}

export default ManageWallet