import React from "react";
import ReactDOM from "react-dom/client";
import { StateProvider } from './context/StateProvider';
import reducer, { initialState } from './context/reducer';
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import ErrorPage from "./components/ErrorPage";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <ErrorBoundary fallback={<ErrorPage />}>
        <App />
      </ErrorBoundary>
    </StateProvider>
  </React.StrictMode>
);
