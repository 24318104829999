import React, { useEffect, useState } from "react"
import { useStateValue } from "../../context/StateProvider"

const CountDown = () => {
    const [{countDownEnds}] = useStateValue()
    const [counter, setCounter] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            const oneSecond = 1000
            const oneMinute = oneSecond*60
            const oneHour = oneMinute*60
            const oneDay = oneHour*24
            const now = Date.now()
            const timeDiff = countDownEnds - now
            if (timeDiff >= 0) {
                let days = Math.floor(timeDiff / oneDay)
                let hours = Math.floor((timeDiff - (days*oneDay)) / oneHour)
                let minutes = Math.floor((timeDiff - ((days*oneDay) + (hours*oneHour))) / oneMinute)
                let seconds = Math.floor((timeDiff - ((days*oneDay) + (hours*oneHour) + (minutes*oneMinute))) / oneSecond)
                if (days < 10) days = `0${days}`
                if (hours < 10) hours = `0${hours}`
                if (minutes < 10) minutes = `0${minutes}`
                if (seconds < 10) seconds = `0${seconds}`
                setCounter({ days, hours, minutes, seconds })
            }
        }, 1000)

        return (() => {
            clearInterval(interval)
        })
    }, [])
    return (
        <div className="timer">
            <div className="timer-title text-xl text-weight-xl cursor-pointer">Start Now!</div>
            <div className="timer-counter">
                <div className="timer-column">
                    <div className="text-xs">Days</div>
                    <div className="text-xl text-weight-l">{counter?.days || "00"}</div>
                </div>
                <div className="text-xl">:</div>
                <div className="timer-column">
                    <div className="text-xs">Hours</div>
                    <div className="text-xl text-weight-l">{counter?.hours || "00"}</div>
                </div>
                <div className="text-xl">:</div>
                <div className="timer-column">
                    <div className="text-xs">Minutes</div>
                    <div className="text-xl text-weight-l">{counter?.minutes || "00"}</div>
                </div>
                <div className="text-xl">:</div>
                <div className="timer-column">
                    <div className="text-xs">Seconds</div>
                    <div className="text-xl text-weight-l">{counter?.seconds || "00"}</div>
                </div>
            </div>
        </div>
    )
}

export default CountDown