import React, { useEffect, useState } from "react"
import { IoSunnySharp, IoMoonSharp } from "react-icons/io5";
import { useStateValue } from "../../context/StateProvider";

const ManageTheme = () => {
    const [{theme}, payload] = useStateValue()
    const [themeIcon, setThemeIcon] = useState(null)

    const toggleTheme = () => {
        payload({
            type: "THEME"
        });
    }

    useEffect(() => {
        if (theme?.toLowerCase() === "dark") setThemeIcon(<IoMoonSharp />)
        else setThemeIcon(<IoSunnySharp />)
    }, [theme])
    return (
        <div className="theme-toggle cursor-pointer" onClick={toggleTheme}>
            <font>{theme}</font>
            {themeIcon}
        </div>
    )
}

export default ManageTheme